import {
  common
} from '@/api/interface'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    menus: []
  },
  getters: {},
  mutations: {
    SET_MENU(state, payload) {
      state.menus = payload
      console.log(payload, '菜单权限')
      if (payload && payload.length > 0 && payload != '12345678') {
        payload.forEach((oneMenu) => {
          // 所有菜单都是二级结构，一级没有页面功能，所以只要添加二级菜单的路由
          if (oneMenu.subMenus && oneMenu.subMenus.length > 0) {
            // const children = []
            oneMenu.subMenus.forEach((twoMenu) => {
              if (twoMenu.subMenus && twoMenu.subMenus.length > 0) {
                // 三级菜单
                twoMenu.subMenus.forEach((threeMenu) => {
                  router.addRoute('admin', {
                    path: threeMenu.link,
                    // 此处使用require，由于import会有奇怪的错误
                    component: (resolve) =>
                      require([`@/views${threeMenu.link}/Index.vue`], resolve),
                    meta: {
                      name1: twoMenu.title,
                      name2: threeMenu.title,
                      requireAuth: true
                    }
                  })
                })
              } else {
                // 二级菜单需匹配页面
                //  判断是否/file路径开头，直接拼接.vue,其他拼接Index.vue
                let arrs = twoMenu.link.split('/')
                if (arrs[1] === 'file') {
                  router.addRoute('admin', {
                    path: twoMenu.link,
                    // 此处使用require，由于import会有奇怪的错误
                    component: (resolve) =>
                      require([`@/views${twoMenu.link}.vue`], resolve),
                    // component: () => import('@/views' + twoMenu.link),
                    meta: {
                      name1: oneMenu.title,
                      name2: twoMenu.title,
                      requireAuth: true
                    }
                  })
                } else {
                  router.addRoute('admin', {
                    path: twoMenu.link,
                    // 此处使用require，由于import会有奇怪的错误
                    component: (resolve) =>
                      require([`@/views${twoMenu.link}/Index.vue`], resolve),
                    // component: () => import('@/views' + twoMenu.link),
                    meta: {
                      name1: oneMenu.title,
                      name2: twoMenu.title,
                      requireAuth: true
                    }
                  })
                }
              }
            })
            let otherMenu = [{
                path: '/unitmanage/Create',
                // component: UnitmanageCreate,
                meta: {
                  name1: '单位管理',
                  name2: '单位创建',
                  requireAuth: true
                }
              },
              {
                path: '/unitrental/Create',
                // component: UnitrentalCreate,
                meta: {
                  name1: '租赁单位管理',
                  name2: '单位创建',
                  requireAuth: true
                }
              },
              {
                path: '/employee/Create',
                // component: EmployeeCreate,
                meta: {
                  name1: '基本工资管理',
                  name2: '基本工资创建',
                  requireAuth: true
                }
              },
              {
                path: '/empsalarystats/queryBackups',
                // component: QueryBackups,
                meta: {
                  name1: '工资管理',
                  name2: '已确认工资',
                  requireAuth: true
                }
              },
              {
                path: '/empwelfare/Create',
                // component: EmpwelfareCreate,
                meta: {
                  name1: '员工福利管理',
                  name2: '福利信息创建',
                  requireAuth: true
                }
              },
              {
                path: '/users/Create',
                // component: UsersCreate,
                meta: {
                  name1: '用户管理',
                  name2: '用户创建',
                  requireAuth: true
                }
              },
              {
                path: '/datarights/Create',
                // component: CreateDataRights,
                meta: {
                  name1: '数据权限',
                  name2: '创建数据权限',
                  requireAuth: true
                }
              },
              {
                path: '/rolerights/Create',
                meta: {
                  name1: '角色权限',
                  name2: '角色权限创建',
                  requireAuth: true
                }
              },
              {
                path: '/roles/Create',
                meta: {
                  name1: '角色',
                  name2: '角色创建',
                  requireAuth: true
                }
              },
              {
                path: '/auths/Create',
                meta: {
                  name1: '权限',
                  name2: '权限创建',
                  requireAuth: true
                }
              },
              {
                path: '/msmnotification/Create',
                meta: {
                  name1: '短信通知',
                  name2: '通知创建',
                  requireAuth: true
                }
              },
              {
                path: '/file/inspectUnitInfo',
                meta: {
                  name1: '各责任单位检查情况',
                  name2: '安装单位工程明细',
                  requireAuth: true
                }
              },
              {
                path: '/file/inspectCountscoreInfo',
                meta: {
                  name1: '厂家检查情况',
                  name2: '生产厂家工程明细',
                  requireAuth: true
                }
              },
              {
                path: '/workinghours/info',
                meta: {
                  name1: '工作时长',
                  name2: '工作时长明细',
                  requireAuth: true
                }
              },
              {
                path: '/carstatics/info',
                meta: {
                  name1: '车辆统计',
                  name2: '行驶详情',
                  requireAuth: true
                }
              },
              {
                path: '/carstatics/abnormal',
                meta: {
                  name1: '车辆统计',
                  name2: '异常停车',
                  requireAuth: true
                }
              }
            ]
            otherMenu.forEach((other) => {
              router.addRoute('admin', {
                path: other.path,
                // 此处使用require，由于import会有奇怪的错误
                component: (resolve) =>
                  require([`@/views${other.path}.vue`], resolve),
                meta: {
                  name1: other.meta.name1,
                  name2: other.meta.name2,
                  requireAuth: true
                }
              })
            })
          }
        })
      }

      // 配置404
      router.addRoute({
        path: '*',
        component: () => import('@/views/404.vue')
      })
      console.log(router, '路由')
    },
    DELETE_MENU(state) {
      state.menus = []
    }
  },
  actions: {
    async FETCH_MENUS(context) {
      const res = await common.post('/user/queryUserMenus', '', '')
      // if (res.code == 200) {
      //菜单列表
      context.commit('SET_MENU', res.data)
      // }
    }
  }
}